const ayarlar_state = {
    dil: "tr",
    iceride: false,
    yukleniyor: true
};

export const ayarlar = function (state = ayarlar_state, action) {
    const { payload, type } = action;

    switch (type) {
        case "uygulama_dilini_guncelle":
            return {
                ...state,
                dil: payload
            };
        case "iceride_durumunu_guncelle":
            return {
                ...state,
                iceride: payload
            };
        case "veri_yukleniyor":
            return {
                ...state,
                yukleniyor: payload
            };
        default:
            return state;
    }
};

export const profil = function (state = null, action) {
    const { payload, type } = action;

    switch (type) {
        case "profil_guncelle":
            return payload;
        default:
            return state;
    }
};

export const yazilar = function (state = {}, action) {
    const { payload, type } = action;

    switch (type) {
        case "yazilari_guncelle":
            return payload;
        default:
            return state;
    }
};
