import { Divider, Drawer, Grid, IconButton, MenuItem, MenuList, Toolbar, Typography } from "@material-ui/core";
import { PowerSettingsNew } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useStyles } from "../malzemeler/gorunum/style";

export default function Menu({ isOpen, onClose }) {
  const { profil, yazilar } = useSelector((state) => state);
  const classes = useStyles();
  const { pathname } = useLocation();

  return !profil ? (
    <></>
  ) : (
    <Drawer open={isOpen} onClose={onClose}>
      <Toolbar className={classes.toolbar}>
        <Grid container justify="space-between" spacing={0}>
          <Grid item xs={9}>
            <Typography variant="body2">{profil.user_display_name}</Typography>
            <Typography variant="caption">{profil.user_email}</Typography>
          </Grid>
          <Grid item xs={3}>
            <IconButton edge="end" color="inherit" component={Link} to="/cikis">
              <PowerSettingsNew />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <MenuList disablePadding>
        <Divider />
        <MenuItem component={Link} selected={pathname === "/"} onClick={onClose} to="/">
          {yazilar.urunler}
        </MenuItem>
        <Divider />
        <MenuItem component={Link} selected={pathname === "/categories"} onClick={onClose} to="/categories">
          {yazilar.kategoriler}
        </MenuItem>
        <Divider />
      </MenuList>
    </Drawer>
  );
}
