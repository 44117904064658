import React, { useCallback, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { kullaniciGirisi } from '../ayarlar/uzak';
import GirisFormu from '../formlar/giris-formu';

const Giris = function ({ ...props }) {
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const { ayarlar, profil, yazilar } = useSelector(state => state);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const giris = useCallback(
        async ({ actions, values }) => {
            try {
                console.log("Giris giris actions, values", actions, values);

                const _profil = await kullaniciGirisi({ kullanici_adi: values.kullanici_adi, sifre: values.sifre });
                console.log("Giris giris _profil", _profil);

                dispatch({ payload: true, type: "iceride_durumunu_guncelle" });
                dispatch({ payload: _profil, type: "profil_guncelle" });
            }
            catch (error) {
                /**
                 * Yapılacaklar: Kullanıcı girişinde hata olduğunda sağ üstten notistack ile uyarı göstermek gerekiyor.
                 */
                console.log("Giris giris error", error);
                enqueueSnackbar(error.message, { variant: "error" })
            }
            finally {
                actions.setSubmitting(false);
            }
        },
        [dispatch, enqueueSnackbar]
    );

    useEffect(
        () => {
            console.log("Giris useEffect ayarlar.iceride profil", ayarlar.iceride, profil)
            ayarlar.iceride && profil !== null && history.replace(from);
        },
        [ayarlar.iceride, from, history, profil]
    );

    return <Grid alignItems="center" container direction="column" spacing={2}>
        <Grid item xs={12}>
            <Typography align="center" color="primary" variant="h6">{yazilar.yonetim_paneli}</Typography>
        </Grid>
        <Grid item xs={12}>
            <GirisFormu onSubmitHook={giris} />
        </Grid>
    </Grid>;
};

export default Giris;
