import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function KontrolluSayfa({ component: Sayfa, kontrol, ...diger_ozellikler }) {
  return (
    <Route
      {...diger_ozellikler}
      render={(props) => {
        return kontrol ? <Sayfa {...props} /> : <Redirect to={{ pathname: "/giris", state: { from: props.location } }} />;
      }}
    />
  );
}
