import { Avatar, Breadcrumbs, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import { Home, Refresh } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { useFormik } from "formik";
import parser from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { urunBilgileriniGuncelle, urunleriGetir, urunVaryantlariniGetir, urunVaryantlariniGuncelle } from "../ayarlar/uzak";

const Urunler = function ({ ...props }) {
  const { profil, yazilar } = useSelector((state) => state);
  const [urunler, setUrunler] = useState([]);
  const [sayfa, setSayfa] = useState(1);
  const [varyantlar, setVaryantlar] = useState([]);
  const [urun, setUrun] = useState(null);
  const [yukleniyor, setYukleniyor] = useState(true);
  const [varyantlar_yukleniyor, setVaryantlarYukleniyor] = useState(false);
  const [sayfalama_yukleniyor, setSayfalamaYukleniyor] = useState(false);
  const [islem_tamamlandi, setIslemTamamlandi] = useState(null);
  const [devam, setDevam] = useState(true);
  const [yayin_durumu, setYayinDurumu] = useState("publish");
  const [yukleniyor_breadcrump, setYukleniyorBreadcrump] = useState(false);
  const { search } = useLocation();
  const category = new URLSearchParams(search).get("category");
  const filterTitle = new URLSearchParams(search).get("title");

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        setIslemTamamlandi(null);
        console.log("Urunler onSubmit values urun", values, urun);

        const _data = {
          update: varyantlar.map((varyant) => ({
            id: varyant.id,
            stock_quantity: values[`${varyant.id}_stok`],
            regular_price: values[`${varyant.id}_fiyat`],
            sale_price: values[`${varyant.id}_indirimli_fiyat`],
          })),
        };

        const _data_urun_bilgileri = {
          name: values[`${urun.id}_urun_ismi`],
          short_description: values[`${urun.id}_urun_aciklamasi`],
          status: values[`${urun.id}_yayin_durumu`],
        };

        if (urun !== null) {
          const _guncellenmis_urun_bilgileri = await urunBilgileriniGuncelle({ bilet: profil.token, data: _data_urun_bilgileri, urun_id: urun.id });
          const _guncellenmis_varyantlar = await urunVaryantlariniGuncelle({ bilet: profil.token, data: _data, urun_id: urun.id });

          setIslemTamamlandi(_guncellenmis_varyantlar);
          setUrun(_guncellenmis_urun_bilgileri);
          setUrunler((_urunler) => _urunler.map((_urun) => (_urun.id === _guncellenmis_urun_bilgileri.id ? _guncellenmis_urun_bilgileri : _urun)));
          setVaryantlar(_guncellenmis_varyantlar.update);

          console.log("_guncellenmis_varyantlar _guncellenmis_urun_bilgileri", _guncellenmis_varyantlar, _guncellenmis_urun_bilgileri);
        }
      } catch (error) {
        setIslemTamamlandi(error);
        console.log("Urunler onSubmit error", error);
      }
    },
    [profil.token, urun, varyantlar]
  );

  const { dirty, handleChange, handleSubmit, isSubmitting, setValues, values, ...form } = useFormik({ initialValues: {}, onSubmit, validateOnChange: false });

  const urunleriSorgula = useCallback(async () => {
    try {
      console.log("Urunler urunler yayin_durumu", yayin_durumu);
      setSayfalamaYukleniyor(true);
      setYukleniyorBreadcrump(true);

      const _urunler = await urunleriGetir({ bilet: profil.token, category, sayfa, status: yayin_durumu });
      console.log("Urunler urunler _urunler.data", _urunler.data);

      setUrunler((u) => {
        const _urunler_toplam = [...u, ..._urunler.data];

        /**
         * Son ürünün yüklendiği bilgisini kontrol edebilmek için urunler değişkenine ihtiyacım var.
         * Fonksiyon içinde dışarıdan eklersem aynı fonksiyon içinde hem urunler hem de setUrunler olduğu için sonsuz döngüye giriyor.
         * useState setter içine fonksiyon alabiliyor ve parametre olarak değişkenin önceki halini veriyor.
         * Bu bilgiye göre yüklenmiş tüm ürünlerin adedini hesaplayarak devam değişkenini true ya da false olarak güncelliyorum.
         * urunler değişkenini setUrunler içinde aldığım için sonsuz döngüye girmiyor.
         */
        setDevam(_urunler_toplam.length !== Number(_urunler.headers["x-wp-total"]));

        return _urunler_toplam;
      });
    } catch (error) {
      console.log("Urunler urunler error", error);
    } finally {
      setYukleniyor(false);
      setSayfalamaYukleniyor(false);
      setYukleniyorBreadcrump(false);
    }
  }, [category, profil.token, sayfa, yayin_durumu]);

  const varyantBilgileriniGetir = useCallback(
    async (urun) => {
      try {
        setVaryantlarYukleniyor(true);
        setUrun(urun);
        const _varyantlar = await urunVaryantlariniGetir({ bilet: profil.token, urun_id: urun.id });

        console.log("Urunler stokBilgileriniGetir _varyantlar urun", _varyantlar, urun);
        setVaryantlar(_varyantlar);

        window.varyantlar = _varyantlar;
      } catch (error) {
        console.log("Urunler stokBilgileriniGetir error", error);
      } finally {
        setVaryantlarYukleniyor(false);
      }
    },
    [profil.token]
  );

  const varyantEkraniniKapat = useCallback(() => {
    setUrun(null);
    setVaryantlar([]);
    setValues({});
    setIslemTamamlandi(null);
  }, [setValues]);

  useEffect(() => {
    urunleriSorgula();
  }, [urunleriSorgula]);

  useEffect(() => {
    setValues(varyantlar.reduce((a, _varyant) => ({ ...a, [`${_varyant.id}_stok`]: _varyant.stock_quantity, [`${_varyant.id}_fiyat`]: _varyant.regular_price, [`${_varyant.id}_indirimli_fiyat`]: _varyant.price }), { ...(urun !== null ? { [`${urun.id}_urun_aciklamasi`]: urun.short_description, [`${urun.id}_urun_ismi`]: urun.name, [`${urun.id}_yayin_durumu`]: urun.status } : {}) }));
  }, [setValues, urun, varyantlar]);

  useEffect(() => {
    console.log("Urunler useEffect islem_tamamlandi", islem_tamamlandi);
  }, [islem_tamamlandi]);

  useEffect(
    function () {
      console.log("Urunler useEffect yayin_durumu", yayin_durumu);

      setYukleniyor(true);
      setUrunler([]);
      setSayfa(1);
    },
    [yayin_durumu]
  );

  return (
    <>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={6}>
          <Breadcrumbs aria-label="breadcrumb">
            <IconButton color="primary" component={Link} edge="start" size="small" to="/">
              <Home />
            </IconButton>
            <Typography>{yazilar.urunler}</Typography>
            {yukleniyor_breadcrump ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton aria-label="refresh" color="primary" edge="end" onClick={urunleriSorgula} size="small">
                <Refresh />
              </IconButton>
            )}
            {!!filterTitle && <Typography>{filterTitle}</Typography>}
            {!!category && (
              <Button component={Link} to="/">
                {yazilar.clear}
              </Button>
            )}
          </Breadcrumbs>
        </Grid>
        <Grid item style={{ textAlign: "right" }} xs={6}>
          <FormControlLabel control={<Switch checked={yayin_durumu === "publish"} onChange={(event) => setYayinDurumu(event.target.checked ? "publish" : "draft")} />} label={yayin_durumu === "publish" ? yazilar.yayindaki_urunler : yazilar.yayinda_olmayan_urunler} labelPlacement="start" />
        </Grid>
        {yukleniyor ? (
          <>
            {Array.from(new Array(7)).map((item, index) => (
              <Grid item key={index.toString()} md={3} sm={6} xs={12}>
                <Card style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                  <CardHeader title={<Skeleton width="80%" />} />
                  <Skeleton variant="rect" height={300} />
                  <CardContent style={{ flexGrow: 1 }}>
                    <Skeleton width="75%" />
                    <Skeleton width="40%" />
                  </CardContent>
                  <CardActions>
                    <Skeleton height={40} width={100} />
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {urunler.map((urun, index) => {
              return (
                <Grid item key={index.toString()} md={3} sm={6} xs={12}>
                  <Card style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <CardHeader subheader={`${yazilar[urun.stock_status]} — ${new Intl.NumberFormat("tr-TR", { style: "currency", currency: "TRY" }).format(urun.price)}`} subheaderTypographyProps={{ variant: "caption" }} title={urun.name} titleTypographyProps={{ variant: "subtitle2" }} />
                    {urun.images && urun.images.length > 0 && <CardMedia component="img" src={urun.images[0].src} title={urun.name} />}
                    <CardContent style={{ flexGrow: 1 }}>
                      {parser(urun.short_description || "")}
                      <Typography variant="caption">{urun.sku}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" fullWidth onClick={() => varyantBilgileriniGetir(urun)} variant="contained">
                        {yazilar.urun_bilgileri}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
            {devam && (
              <Grid item xs={12}>
                <Button color="primary" disabled={sayfalama_yukleniyor} fullWidth onClick={() => setSayfa((sayfa) => sayfa + 1)} variant="contained">
                  {sayfalama_yukleniyor ? <CircularProgress size={25} /> : yazilar.devam}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Dialog aria-labelledby="urun" fullWidth={true} maxWidth={"md"} open={urun !== null} onClose={varyantEkraniniKapat} scroll="body">
        <form onSubmit={handleSubmit}>
          <DialogTitle disableTypography={true} id="form-dialog-title">
            <Grid container direction="row">
              <Grid item md={1} xs={3}>
                {urun !== null && !varyantlar_yukleniyor ? <Avatar alt={urun.name} src={urun.images[0].src} variant="rounded" /> : <Skeleton height={50} variant="circle" width={50} />}
              </Grid>
              <Grid item md={11} xs={9}>
                <Typography variant="subtitle2">{urun !== null && !varyantlar_yukleniyor ? urun.name : <Skeleton width="75%" />}</Typography>
                <Typography variant="caption">{urun !== null && !varyantlar_yukleniyor ? urun.sku : <Skeleton width="30%" />}</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers={true}>
            {varyantlar_yukleniyor ? (
              Array.from(new Array(2)).map((item, index) => <Skeleton height={40} key={index.toString()} width="100%" />)
            ) : (
              <Grid container spacing={3}>
                {islem_tamamlandi !== null && (
                  <Grid xs={12} item>
                    <Alert severity={islem_tamamlandi instanceof Error ? "error" : "success"}>{islem_tamamlandi instanceof Error ? islem_tamamlandi.message : yazilar.guncellendi}</Alert>
                  </Grid>
                )}
                {urun !== null && (
                  <>
                    <Grid item xs={12} md={4}>
                      <TextField defaultValue={urun.name} disabled={isSubmitting} fullWidth id={`${urun.id}_urun_ismi`} label={yazilar.urun_ismi} multiline onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField defaultValue={urun.short_description} disabled={isSubmitting} fullWidth id={`${urun.id}_urun_aciklamasi`} label={yazilar.urun_aciklamasi} multiline onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField disabled={isSubmitting} fullWidth id={`${urun.id}_yayin_durumu`} label={yazilar.yayin_durumu} name={`${urun.id}_yayin_durumu`} onChange={handleChange} select value={values[`${urun.id}_yayin_durumu`]}>
                        <MenuItem value="publish">{yazilar.yayinda}</MenuItem>
                        <MenuItem value="draft">{yazilar.yayinda_degil}</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                )}
                {varyantlar.map((varyant, index) => {
                  const _varyant_beden = varyant ? varyant.attributes.find((attribute) => attribute.name === "Beden") : null;
                  const _varyant_beden_ismi = _varyant_beden ? _varyant_beden.option : "";

                  return Object.keys(values).length > 0 ? (
                    <Grid item key={index.toString()} xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          {yazilar.beden} {_varyant_beden_ismi}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField disabled={isSubmitting} fullWidth id={`${varyant.id}_stok`} label={yazilar.stok} name={`${varyant.id}_stok`} onChange={handleChange} type="number" value={values[`${varyant.id}_stok`]} />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField disabled={isSubmitting} fullWidth id={`${varyant.id}_fiyat`} inputProps={{ inputMode: "decimal", step: 0.01 }} label={yazilar.fiyat} name={`${varyant.id}_fiyat`} onChange={handleChange} type="number" value={values[`${varyant.id}_fiyat`]} />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField disabled={isSubmitting} fullWidth id={`${varyant.id}_indirimli_fiyat`} inputProps={{ inputMode: "decimal", step: 0.01 }} label={yazilar.indirimli} name={`${varyant.id}_indirimli_fiyat`} onChange={handleChange} type="number" value={values[`${varyant.id}_indirimli_fiyat`]} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  );
                })}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {varyantlar_yukleniyor ? (
              <Skeleton height={40} width={100} />
            ) : (
              <>
                <Button disabled={isSubmitting} color="primary" onClick={varyantEkraniniKapat}>
                  {yazilar.kapat}
                </Button>
                <Button disabled={isSubmitting} color="primary" type="submit" variant="contained">
                  {yazilar.guncelle}
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Urunler;
