const dil = {
  acik: {
    en: "Enabled",
    tr: "Açık",
  },
  adres: {
    en: "Address",
    tr: "Adres",
  },
  ana_sayfa: {
    en: "Home",
    tr: "Ana sayfa",
  },
  arama: {
    en: "Search",
    tr: "Arama",
  },
  ayarlar: {
    en: "Settings",
    tr: "Ayarlar",
  },
  beden: {
    en: "Size",
    tr: "Beden",
  },
  bilgi: {
    en: "Info",
    tr: "Bilgi",
  },
  bilgi_sayfalari: {
    en: "Info Pages",
    tr: "Bilgi Sayfaları",
  },
  bulunamadi: {
    en: "Not Found",
    tr: "Bulunamadı",
  },
  calisma_gunleri: {
    en: "Working Days",
    tr: "Çalışma Günleri",
  },
  cikis: {
    en: "Sign out",
    tr: "Çıkış",
  },
  clear: {
    en: "Clear",
    tr: "Temizle",
  },
  daha_fazla: {
    en: "More",
    tr: "Daha Fazla",
  },
  detaylar: {
    en: "Details",
    tr: "Detaylar",
  },
  devam: {
    en: "More",
    tr: "Devam",
  },
  evet: {
    en: "Yes",
    tr: "Evet",
  },
  favoriye_ekleyen_sayisi: {
    en: "Followers",
    tr: "Favoriye Ekleyenler",
  },
  firma: {
    en: "Company",
    tr: "Firma",
  },
  fiyat: {
    en: "Price",
    tr: "Fiyat",
  },
  geri: {
    en: "Back",
    tr: "Geri",
  },
  gerekli: {
    en: "Required",
    tr: "Gerekli",
  },
  giris: {
    en: "Sign in",
    tr: "Giriş",
  },
  gonder: {
    en: "Send",
    tr: "Gönder",
  },
  gun_kaldi: {
    en: "days remain",
    tr: "gün kaldı",
  },
  guncelle: {
    en: "Update",
    tr: "Güncelle",
  },
  guncellendi: {
    en: "Updated.",
    tr: "Güncellendi.",
  },
  hayir: {
    en: "No",
    tr: "Hayır",
  },
  hediyeler: {
    en: "Gifts",
    tr: "Hediyeler",
  },
  hizmetler: {
    en: "Services",
    tr: "Hizmetler",
  },
  indirimli: {
    en: "Discount",
    tr: "İndirimli",
  },
  indirimli_fiyat: {
    en: "Discount Price",
    tr: "İndirimli Fiyat",
  },
  instock: {
    en: "In Stock",
    tr: "Stokta",
  },
  iptal: {
    en: "Cancel",
    tr: "İptal",
  },
  is_yerleri: {
    en: "Local Businesses",
    tr: "İş Yerleri",
  },
  kapali: {
    en: "Disabled",
    tr: "Kapalı",
  },
  kapat: {
    en: "Close",
    tr: "Kapat",
  },
  kategoriler: {
    en: "Categories",
    tr: "Kategoriler",
  },
  kayit_tarihi: {
    en: "Registration Date",
    tr: "Kayıt Tarihi",
  },
  kontrol_edin: {
    en: "Please check",
    tr: "Kontrol edin",
  },
  kullanici_adi: {
    en: "Username",
    tr: "Kullanıcı Adı",
  },
  kullanicilar: {
    en: "Users",
    tr: "Kullanıcılar",
  },
  onay_kodu: {
    en: "Verification Code",
    tr: "Onay Kodu",
  },
  one_cikan: {
    en: "Featured",
    tr: "Öne Çıkan",
  },
  outofstock: {
    en: "Out of stock",
    tr: "Stokta Yok",
  },
  paketler: {
    en: "Packaged",
    tr: "Paketler",
  },
  personel: {
    en: "Workers",
    tr: "Personel",
  },
  plan: {
    en: "Plan",
    tr: "Plan",
  },
  satilacak_urun: {
    en: "Sellable",
    tr: "Satılacak",
  },
  satilmayacak_urun: {
    en: "Unsellable",
    tr: "Satılmayacak",
  },
  sayfa: {
    en: "Page",
    tr: "Sayfa",
  },
  sayfa_basi_sonuc: {
    en: "Rows per page:",
    tr: "Sayfa başı sonuç:",
  },
  sayfa_bulunamadi: {
    en: "Page not found",
    tr: "Sayfa bulunamadı",
  },
  sifre: {
    en: "Password",
    tr: "Şifre",
  },
  siparisler: {
    en: "Orders",
    tr: "Siparişler",
  },
  stok: {
    en: "Stock",
    tr: "Stok",
  },
  stok_bilgileri: {
    en: "Stock Details",
    tr: "Stok Bilgileri",
  },
  telefon: {
    en: "Phone",
    tr: "Telefon",
  },
  urun_aciklamasi: {
    en: "Product Description",
    tr: "Ürün Açıklaması",
  },
  urun_bilgileri: {
    en: "Product Details",
    tr: "Ürün Bilgileri",
  },
  urun_ismi: {
    en: "Product Name",
    tr: "Ürün İsmi",
  },
  urunler: {
    en: "Products",
    tr: "Ürünler",
  },
  varyant_bulunamadi: {
    en: "No results.",
    tr: "Varyant bulunamadı.",
  },
  yayin_durumu: {
    en: "Status",
    tr: "Yayın Durumu",
  },
  yayinda: {
    en: "Publish",
    tr: "Yayında",
  },
  yayinda_degil: {
    en: "Draft",
    tr: "Yayında Değil",
  },
  yayindaki_urunler: {
    en: "Published Products",
    tr: "Yayındaki Ürünler",
  },
  yayinda_olmayan_urunler: {
    en: "Draft Products",
    tr: "Yayında Olmayan Ürünler",
  },
  yonetim_paneli: {
    en: "Admin Panel",
    tr: "Yönetim Paneli",
  },
};

export default dil;
