import React, { useCallback } from 'react';

import { useFormik } from 'formik';
import { Button, Grid, TextField, Paper, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { useStyles } from '../malzemeler/gorunum/style';

const GirisFormu = function ({
    onSubmitHook = ({ actions, values }) => console.log("GirisFormu onSubmitHook actions values", actions, values),
    ...props
}) {
    const classes = useStyles();
    const { yazilar } = useSelector(state => state);

    const onSubmit = useCallback(
        (values, actions) => {
            onSubmitHook({ actions, values });
        },
        [onSubmitHook]
    );

    const validationSchema = Yup.object().shape({ kullanici_adi: Yup.string().required(yazilar.gerekli), sifre: Yup.string().required(yazilar.gerekli) });
    const { dirty, errors, handleChange, handleSubmit, isSubmitting, values, ...form } = useFormik({ initialValues: { kullanici_adi: "", sifre: "" }, onSubmit, validationSchema });

    return <Paper className={classes.icerik}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField error={"kullanici_adi" in errors} fullWidth helperText={"kullanici_adi" in errors && errors.kullanici_adi} id="kullanici_adi" label={yazilar.kullanici_adi} name="kullanici_adi" onChange={handleChange} value={values.kullanici_adi} />
                </Grid>
                <Grid item xs={12}>
                    <TextField error={"sifre" in errors} fullWidth helperText={"sifre" in errors && errors.sifre} id="sifre" label={yazilar.sifre} name="sifre" onChange={handleChange} type="password" value={values.sifre} />
                </Grid>
                <Grid item xs={12}>
                    <Button color="primary" disabled={!dirty || isSubmitting} fullWidth type="submit" variant="contained">
                        {isSubmitting ? <CircularProgress color="inherit" size={24} /> : yazilar.giris}
                    </Button>
                </Grid>
            </Grid>
        </form>
    </Paper>;
};

export default GirisFormu;
