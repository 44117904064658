import axios from "axios";

export const internet_sitesi_api_url = process.env.REACT_APP_API_URL_BASE;

export const kullaniciGirisi = async ({ kullanici_adi: username, sifre: password }) => {
  try {
    const _cevap = await axios.post(internet_sitesi_api_url + "/jwt-auth/v1/token", { username, password });
    console.log(_cevap);

    return new Promise((resolve, reject) => {
      /**
       * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
       */

      if (_cevap.data.status === "error") {
        reject(new Error(`Kullanıcı girişi servisinde hata oluştu.`));
        return;
      }

      resolve(_cevap.data);
    });
  } catch (error) {
    /**
     * Servise hatalı bilet yollandığı zaman kullaniciKontrolu fonksiyonu buraya düşüyor.
     */

    return new Promise((resolve, reject) => {
      reject(new Error(`Hata oluştu: ${error.message}`));
    });
  }
};

export const kullaniciKontrolu = async ({ bilet }) => {
  try {
    const _cevap = await axios.post(internet_sitesi_api_url + "/jwt-auth/v1/token/validate", null, { headers: { Authorization: `Bearer ${bilet}` } });
    console.log("uzak.js kullaniciKontrolu _cevap", _cevap);

    return new Promise((resolve, reject) => {
      /**
       * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
       */

      if (!_cevap) {
        reject(new Error("Giriş"));
        return;
      }

      if (_cevap.data === undefined) {
        reject(new Error("Farklı cevap"));
        return;
      }

      resolve(_cevap.data);
    });
  } catch (error) {
    /**
     * Servise hatalı bilet yollandığı zaman kullaniciKontrolu fonksiyonu buraya düşüyor.
     */

    return new Promise((resolve, reject) => {
      reject(new Error(`Hata oluştu: ${error.message}`));
    });
  }
};

export const urunBilgileriniGuncelle = async ({ bilet, data, urun_id }) => {
  try {
    console.log("uzak.js urunBilgileriniGuncelle data", data);
    const _cevap = await axios.put(`${internet_sitesi_api_url}/wc/v3/products/${urun_id}`, data, { headers: { Authorization: `Bearer ${bilet}` } });
    console.log("uzak.js urunBilgileriniGuncelle _cevap", _cevap);

    return new Promise((resolve, reject) => {
      /**
       * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
       */

      if (!_cevap) {
        reject(new Error("Ürün bilgileri"));
        return;
      }

      if (_cevap.data === undefined) {
        reject(new Error("Farklı cevap"));
        return;
      }

      resolve(_cevap.data);
    });
  } catch (error) {
    /**
     * Servise hatalı bilet yollandığı zaman kullaniciKontrolu fonksiyonu buraya düşüyor.
     */

    return new Promise((resolve, reject) => {
      reject(new Error(`Hata oluştu: ${error.message}`));
    });
  }
};

export const urunleriGetir = async ({ bilet, category = "", sayfa: page = 1, sayfa_basi_sonuc: per_page = 100, status = "publish" }) => {
  try {
    const _cevap = await axios.get(`${internet_sitesi_api_url}/wc/v3/products${!!category ? `?category=${category}` : ""}`, { headers: { Authorization: `Bearer ${bilet}` }, params: { status, page, per_page } });
    console.log("uzak.js urunleriGetir _cevap", _cevap);

    return new Promise((resolve, reject) => {
      /**
       * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
       */

      if (!_cevap) {
        reject(new Error("Ürün bilgileri"));
        return;
      }

      if (_cevap.data === undefined) {
        reject(new Error("Farklı cevap"));
        return;
      }

      resolve(_cevap);
    });
  } catch (error) {
    /**
     * Servise hatalı bilet yollandığı zaman kullaniciKontrolu fonksiyonu buraya düşüyor.
     */

    return new Promise((resolve, reject) => {
      reject(new Error(`Hata oluştu: ${error.message}`));
    });
  }
};

export const urunVaryantlariniGetir = async ({ bilet, urun_id }) => {
  try {
    const _cevap = await axios.get(`${internet_sitesi_api_url}/wc/v3/products/${urun_id}/variations`, { headers: { Authorization: `Bearer ${bilet}` } });
    console.log("uzak.js urunVaryantlariniGetir _cevap", _cevap);

    return new Promise((resolve, reject) => {
      /**
       * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
       */

      if (!_cevap) {
        reject(new Error("Ürün bilgileri"));
        return;
      }

      if (_cevap.data === undefined) {
        reject(new Error("Farklı cevap"));
        return;
      }

      resolve(_cevap.data);
    });
  } catch (error) {
    /**
     * Servise hatalı bilet yollandığı zaman kullaniciKontrolu fonksiyonu buraya düşüyor.
     */

    return new Promise((resolve, reject) => {
      reject(new Error(`Hata oluştu: ${error.message}`));
    });
  }
};

export const urunVaryantlariniGuncelle = async ({ bilet, data, urun_id }) => {
  try {
    console.log("uzak.js urunVaryantlariniGuncelle data urun_id bilet", data, urun_id, bilet);
    const _cevap = await axios.post(`${internet_sitesi_api_url}/wc/v3/products/${urun_id}/variations/batch`, data, { headers: { Authorization: `Bearer ${bilet}` } });
    console.log("uzak.js urunVaryantlariniGuncelle _cevap", _cevap);

    return new Promise((resolve, reject) => {
      /**
       * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
       */

      if (!_cevap) {
        reject(new Error("Ürün bilgileri"));
        return;
      }

      if (_cevap.data === undefined) {
        reject(new Error("Farklı cevap"));
        return;
      }

      resolve(_cevap.data);
    });
  } catch (error) {
    /**
     * Servise hatalı bilet yollandığı zaman kullaniciKontrolu fonksiyonu buraya düşüyor.
     */

    return new Promise((resolve, reject) => {
      reject(new Error(`Hata oluştu: ${error.message}`));
    });
  }
};
