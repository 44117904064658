import { makeStyles } from "@material-ui/core";

import renkler from "../../ayarlar/renkler";

export const useStyles = makeStyles((theme) => {
  console.log("theme", theme);
  return {
    breadcrump_ikonulu_baglanti: { display: "flex" },
    daha_fazla: { textAlign: "center" },
    dialog_icinde_tablo: { padding: 0, overflow: "none !important" },
    icerik: { padding: 20 },
    ikon: { height: 20, marginBottom: 5, marginRight: 5, width: 20 },
    logo: { flexGrow: 1 },
    toolbar: { paddingLeft: 15, paddingRight: 15 },
    ust_menu_ikon: { color: renkler.beyaz },
    yayinda_alani: { textAlign: "right" },
  };
});
