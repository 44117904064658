import React, { useCallback, useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';

function Cikis(props) {
    const [cikis_yapildi, setCikisYapildi] = useState(false);

    let { cikis } = props;

    const kullaniciCikisi = useCallback(
        async () => {
            try {
                await cikis();

                setCikisYapildi(true);
            }
            catch (error) {
                console.log("Cikis kullaniciCikisi error", error);
                await cikis();

                setCikisYapildi(true);
            }
        },
        [cikis]
    )

    useEffect(
        () => {
            console.log("Cikis useEffect");
            kullaniciCikisi();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return cikis_yapildi && <Redirect to="/giris" />;
}

export default Cikis;
