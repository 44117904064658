const renkler = {
    acik_gri: "#ebebeb",
    acik_sari: "#fffae3",
    beyaz: "#ffffff",
    gri: "#cccccc",
    kirmizi: "#f4342e",
    koyu_gri: "#999999",
    koyu_yesil: "#09A129",
    koyu_sari: "#D89B00",
    pembe: "#e62194",
    sari: "#EFCA08",
    siyah: "#000000",
    turuncu: "#F08700",
    yesil: "#27ce8c",
    _form_border: "#D9D5DC",
    _sayfa_notu: "#666666",
    _gri_yazi: "#808080",
    _sekmeler_arkaplan: "#f7f7f7"
}

export default renkler;