import React from 'react';

import { Chip, Grid, Paper } from '@material-ui/core';

import { useStyles } from '../malzemeler/gorunum/style';

function SayfaBulunamadi(props) {
    const classes = useStyles();

    return <Grid container>
        <Grid item xs={12}>
            <Paper className={classes.icerik}>
                Sayfa bulunamadı: <Chip label={props.location.pathname} />
            </Paper>
        </Grid>
    </Grid>;
}

export default SayfaBulunamadi;
