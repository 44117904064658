import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
    MuiDrawer: {
      paper: {
        width: "250px",
      },
    },
  },
  palette: {
    background: {
      default: "#ebebeb",
    },
    primary: {
      main: "#000000",
    },
  },
  props: {
    MuiSkeleton: {
      animation: "wave",
    },
  },
  shape: {},
  typography: {},
});
