import { CircularProgress, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Paper, Switch } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWordpress } from "../ayarlar/hooks";

export default function Categories() {
  const { getMenu, menu, loadingMenu, openCloseCategories, source, updatingCategory } = useWordpress();

  useEffect(() => {
    getMenu();
    console.count("getMenu");

    return () => {
      if (source) {
        console.log("Canceling getCategories request...");
        source.cancel("Kategoriler sayfasından çıkıldı.");
      }
    };
  }, [getMenu, source]);

  return loadingMenu ? (
    <CircularProgress />
  ) : (
    <>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {menu.map((menuItem) => (
            <Grid item key={menuItem.ID} xs={12} md={4}>
              <Paper>
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      {menuItem.title}
                    </ListSubheader>
                  }
                >
                  {menuItem?.child_items?.map((child_item) => (
                    <ListItem button component={Link} disabled={!!updatingCategory} key={child_item.ID} to={`/?category=${child_item.object_id}&title=${child_item?.title}`}>
                      <ListItemText primary={child_item?.title} />
                      <ListItemSecondaryAction>
                        {updatingCategory === child_item.ID && <CircularProgress size={20} />}
                        <Switch
                          disabled={!!updatingCategory}
                          edge="end"
                          onChange={async () => {
                            await openCloseCategories({ menuItem: child_item, status: child_item.post_status === "publish" ? "draft" : "publish" });
                          }}
                          checked={child_item.post_status === "publish"}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
