import Axios from "axios";
import { groupBy } from "lodash";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { internet_sitesi_api_url } from "./uzak";

export function useWordpress() {
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [loadingOpenCloseCategories, setLoadingOpenCloseCategories] = useState(false);
  const [menu, setMenu] = useState([]);
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  const { profil } = useSelector((state) => state);
  const [source] = useState(Axios.CancelToken.source());
  const [updatingCategory, setUpdatingCategory] = useState(null);

  const getCategories = useCallback(async () => {
    try {
      if (!profil?.token) {
        throw new Error("Kullanıcı girişi yapılmamış.");
      }
      setLoadingCategories(true);

      const response = await Axios.get(`${internet_sitesi_api_url}/wp/v2/product_cat?per_page=100`, {
        headers: { Authorization: `Bearer ${profil.token}` },
        cancelToken: source.token,
      });
      console.log("🚀 ~ file: hooks.js:19 ~ getMenu ~ response:", response);

      if (!!response?.data) {
        const groupedCategories = Object.values(groupBy(response.data, "parent"));
        console.log("🚀 ~ file: hooks.js:32 ~ getCategories ~ groupedCategories:", groupedCategories);
        setCategories(groupedCategories);
      }
    } catch (error) {
      console.error(`getCategories: ${error?.message || "Bilinmeyen hata."}`);
      if (error?.response?.status === 401) history.push("/cikis");
    } finally {
      setLoadingCategories(false);
    }
  }, [history, profil, source.token]);

  const getMenu = useCallback(
    async (updateLoadingStatus = true) => {
      try {
        if (!profil?.token) throw new Error("Kullanıcı girişi yapılmamış.");

        if (updateLoadingStatus) setLoadingMenu(true);

        const response = await Axios.get(`${internet_sitesi_api_url}/menus/v1/menus/ustmenu`, {
          headers: { Authorization: `Bearer ${profil.token}` },
          cancelToken: source.token,
        });

        if (!!response?.data?.items) {
          setMenu(response.data.items);
        }
      } catch (error) {
        console.error(`getMenu: ${error?.message || "Bilinmeyen hata."}`);
        if (error?.response?.status === 401) history.push("/cikis");
      } finally {
        if (updateLoadingStatus) setLoadingMenu(false);
      }
    },
    [history, profil, source.token]
  );

  const getOrders = useCallback(async () => {
    try {
      if (!profil?.token) {
        throw new Error("Kullanıcı girişi yapılmamış.");
      }
      setLoadingOrders(true);

      const response = await Axios.get(`${internet_sitesi_api_url}/wp/v2/product_cat?per_page=100`, {
        headers: { Authorization: `Bearer ${profil.token}` },
        cancelToken: source.token,
      });
      console.log("🚀 ~ file: hooks.js:19 ~ getMenu ~ response:", response);

      if (!!response?.data) {
        setOrders(response.data);
      }
    } catch (error) {
      console.error(`getCategories: ${error?.message || "Bilinmeyen hata."}`);
      if (error?.response?.status === 401) history.push("/cikis");
    } finally {
      setLoadingOrders(false);
    }
  }, [history, profil, source.token]);

  const openCloseCategories = useCallback(
    async ({ menuItem, status }) => {
      try {
        if (!profil?.token) {
          throw new Error("Kullanıcı girişi yapılmamış.");
        }
        setLoadingOpenCloseCategories(true);

        let update = [];

        /**
         *
         */
        setUpdatingCategory(menuItem.ID);

        /**
         * Updates post_status of menu item.
         */
        const menuItemUpdateResponse = await Axios.post(
          `${internet_sitesi_api_url}/wp/v2/nav_menu_item/${menuItem.ID}`,
          {
            status,
          },
          {
            headers: { Authorization: `Bearer ${profil.token}` },
            cancelToken: source.token,
          }
        );
        console.log("🚀 ~ file: hooks.js:33 ~ menuItemUpdateResponse:", menuItemUpdateResponse);

        /**
         * Gets all product ids of category.
         * TO DO: This section only gets maximum 100 products. Refactor this section to get all products or make this area works with pagination.
         */
        const productsOfCategoryResponse = await Axios.get(`${internet_sitesi_api_url}/wc/v3/products?category=${menuItem.object_id}&_fields=id,status&per_page=100`, {
          headers: { Authorization: `Bearer ${profil.token}` },
          cancelToken: source.token,
        });
        console.log("🚀 ~ file: hooks.js:129 ~ productsOfCategoryResponse:", productsOfCategoryResponse);

        if (!!productsOfCategoryResponse?.data && Array.isArray(productsOfCategoryResponse.data)) {
          update = productsOfCategoryResponse.data.map((product) => ({ ...product, status }));
          console.log("🚀 ~ file: hooks.js:137 ~ update:", update);
        }

        /**
         * Updates post_status of products to draft or publish.
         */
        const updateProductsResponse = await Axios.post(
          `${internet_sitesi_api_url}/wc/v3/products/batch?_fields=id,status`,
          {
            update,
          },
          {
            headers: { Authorization: `Bearer ${profil.token}` },
            cancelToken: source.token,
          }
        );
        console.log("🚀 ~ file: hooks.js:152 ~ updateProductsResponse:", updateProductsResponse);

        /**
         * Gets all menu items.
         */
        await getMenu(false);
      } catch (error) {
        console.error(`openCloseCategories: ${error?.message || "Bilinmeyen hata."}`);
        if (error?.response?.status === 401) history.push("/cikis");
      } finally {
        setLoadingCategories(false);
        setUpdatingCategory(null);
      }
    },
    [getMenu, history, profil, source.token]
  );

  return { categories, getCategories, getMenu, getOrders, loadingCategories, loadingMenu, loadingOpenCloseCategories, loadingOrders, menu, openCloseCategories, orders, source, updatingCategory };
}
