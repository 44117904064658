import { AppBar, Box, CircularProgress, Container, CssBaseline, Grid, IconButton, Toolbar } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Menu as MenuIcon } from "@material-ui/icons";
import { SnackbarProvider } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import { dileGoreYazilariHazirla } from "./ayarlar/fonksiyonlar";
import { kullaniciKontrolu } from "./ayarlar/uzak";
import { ayarlar, profil, yazilar } from "./ayarlar/veriler";
import KontrolluSayfa from "./bilesenler/kontrollu-sayfa";
import Menu from "./bilesenler/menu";
import { useStyles } from "./malzemeler/gorunum/style";
import { theme } from "./malzemeler/gorunum/theme";
import Categories from "./sayfalar/categories";
import Cikis from "./sayfalar/cikis";
import Giris from "./sayfalar/giris";
import Orders from "./sayfalar/orders";
import SayfaBulunamadi from "./sayfalar/sayfa-bulunamadi";
import Products from "./sayfalar/urunler";

const appReducer = combineReducers({ ayarlar, profil, yazilar });
const persistConfig = { key: "root", storage, blacklist: ["ayarlar"] };
const persistedReducer = persistReducer(persistConfig, appReducer);
const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
let persistor = persistStore(store);
//persistor.purge(); /* BU SATIR redux-persist NEDENIYLE HAFIZADA KALAN DATAYI TEMIZLEMEK GEREKTIGINDE KULLANILIR */

function Sayfalar() {
  const classes = useStyles();
  const { ayarlar, profil } = useSelector((state) => state);
  const dispatch = useDispatch();
  const bilet = useRef(profil !== null && profil.token !== undefined ? profil.token : "");
  const [menu, setMenu] = useState(false);

  function onCloseMenu() {
    setMenu(false);
  }

  const { iceride, yukleniyor } = ayarlar;

  const cikis = useCallback(() => {
    dispatch({ payload: false, type: "iceride_durumunu_guncelle" });
    dispatch({ payload: null, type: "profil_guncelle" });
  }, [dispatch]);

  const kullanici = useCallback(async () => {
    try {
      const _kullanici_durumu = await kullaniciKontrolu({ bilet: bilet.current });
      console.log("Sayfalar kullanici _kullanici_durumu", _kullanici_durumu);

      if (_kullanici_durumu.code !== "jwt_auth_valid_token") {
        /**
         * Yapılacaklar: Error mesajlarının çoklu dile uygun hale getirilmesi gerekiyor.
         */
        throw new Error("Kullanıcı giriş yapması gerekiyor.");
      }

      dispatch({ payload: true, type: "iceride_durumunu_guncelle" });
    } catch (error) {
      console.log("Sayfalar kullanici error.message", error.message);
      cikis();
    } finally {
      dispatch({ payload: false, type: "veri_yukleniyor" });
    }
  }, [cikis, dispatch]);

  useEffect(() => {
    dispatch({ payload: dileGoreYazilariHazirla(ayarlar.dil), type: "yazilari_guncelle" });
  }, [ayarlar.dil, dispatch]);

  useEffect(() => {
    /**
     * Yapılacaklar: Uygulama ilk açıldığında bilet kontrolünü yapan kodlar buraya yazılacak.
     */

    kullanici();

    // dispatch({ payload: kullanici, type: "profil_guncelle" });
    // dispatch({ payload: Boolean(kullanici), type: "iceride_durumunu_guncelle" });
    // dispatch({ payload: yukleniyor, type: "veri_yukleniyor" });
  }, [kullanici]);

  return (
    <>
      <Menu isOpen={menu} onClose={onCloseMenu} />
      <AppBar color="default" position="static">
        <Toolbar>
          {iceride && (
            <IconButton color="inherit" edge="start" onClick={() => setMenu(true)}>
              <MenuIcon />
            </IconButton>
          )}
          <Box className={classes.logo}>Flow by Eda Tümer</Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            {!yukleniyor ? (
              <Switch>
                <KontrolluSayfa component={Products} exact kontrol={iceride} path="/" />
                <KontrolluSayfa component={Categories} exact kontrol={iceride} path="/categories" />
                <KontrolluSayfa component={Orders} exact kontrol={iceride} path="/orders" />
                <Route render={(props) => <Cikis cikis={cikis} {...props} />} path="/cikis" />
                <Route render={(props) => <Giris cikis={cikis} {...props} />} path="/giris" />
                <Route component={SayfaBulunamadi} />
              </Switch>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );

  /* const user = useSelector(({ kullanici }) => kullanici)
  const isAuthed = user !== undefined && user.username !== undefined && user.username.length



  return <Switch>
    <PublicRoute component={Giris} path="/giris" redirectTo="/" isAuthed={isAuthed} />
    <Route render={() => <Cikis />} path="/cikis" />
    <PrivateRoute path="/" redirectTo="/giris" isAuthed={isAuthed} component={Anasayfa} />
    <Route component={SayfaBulunamadi} />
  </Switch> */
}

const DGWooCommercePazarYerleriVeUrunYonetimiEklentisi = function () {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }} autoHideDuration={5000} dense={true} maxSnack={3}>
            <BrowserRouter>
              <CssBaseline />
              <Sayfalar />
            </BrowserRouter>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  );
};

export default DGWooCommercePazarYerleriVeUrunYonetimiEklentisi;
